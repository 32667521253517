import { AnswerComponent } from './AnswerComponent/AnswerComponent'
import React from 'react'

type Answer = string | React.ComponentType;

export const list: Array<{ question: string, answer: Answer}> = [
    {
        question: 'За что эксперты получают баллы и сколько?',
        answer: AnswerComponent,
    },
    {
        question: 'Как закрывается задача ментора и консультанта?',
        answer: 'После завершения взаимодействия с менти или заказчиком, в личном кабинете измени статус на «Завершено». Менти или заказчик получит письмо с просьбой оценить ваше взаимодействие, а ты автоматически получишь баллы.',
    },
    {
        question: 'Как происходит зачисление бонусов?',
        answer: 'Зачисление бонусов происходит моментально либо в течение суток.',
    },
    {
        question: 'Как осуществляется выдача призов?',
        answer: () => <div style={{ paddingTop: "15px" }}> Подробнее о правилах выдачи призов <a href="https://confluence.o3.ru/pages/viewpage.action?pageId=581485909" target="_blank" rel="noreferrer" style={{color: "#005BFF"}}>здесь</a></div>,
    },
]

export const servicesItems = [
    {
        title: 'Ментор',
        services: [
            {
                service: 'Стоимость 1-2 ментор-сессии',
                price: 1000,
            },
            {
                service: 'Проведение цикла ментор-сессий(3 и более)',
                price: 4000,
            },
        ],
    },
    {
        title: 'Консультант',
        services: [
            {
                service: 'Проведение 1-2 консультации',
                price: 1000,
            },
            {
                service: 'Проведении цикла консультаций (3 и более)',
                price: 3000,
            },
        ],
    },
    {
        title: 'Внутренний тренер',
        services: [
            {
                service: 'Разработка программы',
                price: 4000,
            },
            {
                service: 'Проведение 1 тренинга',
                price: 2000,
            },
        ],
    },
]

export const conditions = [
    {
        title: 'Ментор',
        price: 'Каждые 5 заявок',
    },
    {
        title: 'Консультант',
        price: 'Каждые 10 заявок',
    },
    {
        title: 'Тренер',
        price: 'Каждые 15 заявок',
    },
]
